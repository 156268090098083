<template>
  <!-- 拼团 -->
  <div>
    <div class="pt-banner">
      <img src="@/assets/images/pintuan_images/top-2.png" alt="" />
      <div class="pt-progress">
        <img src="../../assets/images/pintuan_images/lc-ad.png" alt="" />
      </div>
      <div class="pt-clk" @click="getCombos">
        <img src="@/assets/images/pintuan_images/clk-button.png" alt="" />
      </div>
    </div>
    <div class="pt-wrapper">
      <div class="container">
        <div class="pt-panel">
          <div
            class="pt-panel-body"
            v-if="selfGroups.length == 0 && allGroup.length == 0"
          >
            <div class="pt-panel-hd">
              <img src="../../assets/images/pintuan_images/tit-1.png" alt="" />
            </div>
            <div class="pt-panel-hd">
              <img src="../../assets/images/pintuan_images/monkey.png" />
            </div>
          </div>
          <div class="pt-panel-body" v-else>
            <div class="pt-panel-hd">
              <img src="../../assets/images/pintuan_images/tit-1.png" alt="" />
            </div>
            <div class="pt-list">
              <div
                class="pt-list-card flex flex-ac"
                v-for="(item, index) in isShort ? shortSelfGroups : selfGroups"
                :key="index"
              >
                <div class="list-pic"><img :src="item.cover" alt="" /></div>
                <div class="flex_bd">
                  <div class="pt-list-title">【 {{ item.buyComboName }} 】</div>
                  <div class="pt-time">
                    <mv-count-down
                      :startTime="startTime"
                      :endTime="item.terminationTime"
                      :endText="item.status.name"
                      :dayTxt="'天'"
                      :hourTxt="':'"
                      :minutesTxt="':'"
                      :secondsTxt="''"
                      :isStart="true"
                    ></mv-count-down>
                  </div>
                </div>
                <div class="pt-group flex flex-ac">
                  <div class="group-item">
                    <img :src="item.openGroupUser.portrait" />
                    <el-button class="btn-star">发起人</el-button>
                  </div>
                  <div class="plus-icon"></div>
                  <div v-for="(it, i) in item.members" :key="i">
                    <div class="group-item">
                      <img :src="it.portrait" />
                      <el-button class="btn-mem">组员</el-button>
                    </div>
                  </div>
                  <div
                    v-for="(count, index) in Math.abs(
                      item.limitNum - item.members.length - 1
                    )"
                    :key="index"
                  >
                    <div class="group-item">
                      <div class="item-add"></div>
                      <el-button class="btn-mem">组员</el-button>
                    </div>
                  </div>
                </div>
                <div class="list-button" v-if="item.status.value == '0'">
                  <el-button
                    class="btn-together"
                    @click="getLink(item.groupBuyId)"
                  ></el-button>
                  <el-button
                    class="btn-out"
                    @click="backGroupOpen(item.groupBuyId)"
                  ></el-button>
                </div>
                <div class="list-button" v-else-if="item.status.value == '1'">
                  <div class="pt-success">拼团成功！</div>
                </div>
                <div class="list-button" v-else-if="item.status.value == '2'">
                  <div class="pt-false">拼团失败！</div>
                </div>
              </div>
            </div>
            <div
              class="pt-div-more"
              v-if="selfGroups.length > 3"
              @click="isShort = !isShort"
            >
              <span>显示更多</span>
            </div>
            <div class="pt-panel-hd">
              <img src="../../assets/images/pintuan_images/tit-2.png" alt="" />
            </div>
            <div class="pt-list">
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in allGroup" :key="index">
                  <div class="pt-list-card flex flex-ac">
                    <div class="list-pic"><img :src="item.cover" alt="" /></div>
                    <div class="flex_bd">
                      <div class="pt-list-title">
                        【 {{ item.buyComboName }} 】
                      </div>
                      <div class="pt-time">
                        <mv-count-down
                          :startTime="startTime"
                          :endTime="item.terminationTime"
                          :endText="item.status.name"
                          :dayTxt="'天'"
                          :hourTxt="':'"
                          :minutesTxt="':'"
                          :secondsTxt="''"
                          :isStart="true"
                        ></mv-count-down>
                      </div>
                    </div>
                    <div class="pt-group flex flex-ac">
                      <div class="group-item">
                        <img :src="item.openGroupUser.portrait" />
                        <el-button class="btn-star">发起人</el-button>
                      </div>
                      <div class="plus-icon"></div>
                      <div v-for="(it, i) in item.members" :key="i">
                        <div class="group-item">
                          <img :src="it.portrait" />
                          <el-button class="btn-mem">组员</el-button>
                        </div>
                      </div>
                      <div
                        v-for="(count, index) in Math.abs(
                          item.limitNum - item.members.length - 1
                        )"
                        :key="index"
                      >
                        <div class="group-item">
                          <div class="item-add"></div>
                          <el-button class="btn-mem">组员</el-button>
                        </div>
                      </div>
                    </div>
                    <div class="list-button">
                      <el-button
                        class="btn-together"
                        @click="checkMember(item, item.groupBuyId)"
                      ></el-button>
                      <div class="rt-tip">
                        还差{{
                          Math.abs(item.limitNum - item.members.length - 1)
                        }}人
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
        <div class="pt-rular">
          <div class="pt-rular-hd">
            <img src="../../assets/images/pintuan_images/gz-text.png" alt="" />
          </div>
          <div class="rular-body">
            <p>活动时间：2021年9月1日 00:00 — 2021年9月30日 24:00</p>
            <p>
              适用商品：北美SDE算法速成班&lt;高级版&gt;、北美SDE求职速成班&lt;高级版&gt;
              有效期120天、北美SDE求职旗舰班 有效期365天；
            </p>
            <p>
              参与方式：从专场活动页面点击拼团专区，进入拼团页面点击「发起拼团」即可参与拼团。拼团有效期24小时。拼成后自动开通课程。超时未拼成，订单失效，支付金额原渠道自动退回。
            </p>
            <p>其他说明：</p>
            <p>
              ①
              关于发起拼团：用户发起拼团后，需足额支付个人拼团费用，15分钟未支付，拼团订单无效，支付成功即表示拼团成功发起；
            </p>
            <p>② 关于拼团成功：拼团成功需满足2人全部成功支付拼团费用；</p>
            <p>③ 关于课程开通：拼团成功后，即自动开通课程；</p>
            <p>
              ④ 关于拼团有效期：自拼团发起之日起，24小时有效。例：2021年9月16日
              12:00:00 发起拼团，则2021年9月17日 12:00:00 拼团失效；
            </p>
            <p>
              ⑤ 关于退款：拼团未成功，系统自动发起退款，用户原支付渠道退回。
              温馨提示：每位用户每门课程仅可参与一次拼团，多次拼团，结果无效；
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- div.gift -->
    <div class="gift-fixed" @click="visible = !visible">
      <div class="box-popover" v-if="visible">
        <div class="inner">
          <img src="../../assets/activity_images/pc/wx.png" />
          <div class="txt1">扫描微信二维码<br />课程和网站疑问</div>
          <div class="txt2">针对优惠有疑问?<br />购买时遇到问题?</div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      width="720px"
      custom-class="pt-dialog"
      :visible.sync="dialogFormVisible"
    >
      <div class="dialog-main">
        <div class="log-head" style="">
          <span class="log-tit">职场升“值”季，拼一把</span>
        </div>
        <div class="log-row flex flex-ac">
          <div class="log-label">选择拼团课程</div>
          <div class="flex_bd">
            <!--            <el-radio-group v-model="radioV" size="small" >-->
            <!--              <el-radio :label="index" :value="index" border v-for="(item,index) in activeDetails" :key="index"-->
            <!--              >{{ item.buyComboName }}</el-radio>-->
            <!--            </el-radio-group>-->
            <el-select v-model="radioV" placeholder="请选择">
              <el-option
                v-for="(item, index) in activeDetails"
                :key="index"
                :label="item.buyComboName"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="log-row flex flex-ac"
          v-for="(item, index) in activeDetails"
          :key="index"
        >
          <template v-if="radioV == index">
            <div class="log-label" style="margin-right: 45px">已选课程</div>
            <div class="flex_bd">
              <div class="ke-card flex flex-ac" style="max-width: 420px">
                <div class="k-photo">
                  <img :src="item.cover" alt="" />
                </div>
                <div class="flex_bd">
                  <div class="k-title" style="font-size: 14px">
                    【{{ item.buyComboName }}】
                  </div>
                  <div class="k-info" style="font-size: 14px; margin-left: 8px">
                    课程有效期:{{ item.duration }}天
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="log-foot">
          <div class="log-tip">拼团不成功,全额自动退款</div>
          <el-button class="btn-pl" @click="checkMember(activeDetails[radioV])"
            >立即发起</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗 -->
    <el-dialog
      width="720px"
      custom-class="pt-dialog"
      :visible.sync="dialogFormVisible2"
    >
      <div class="dialog-success">
        <div class="log-head">
          <span class="log-tit">发起成功！快去邀请你的好友吧！</span>
        </div>
        <div class="d-flex-code flex flex-ac">
          <div class="txt">专属邀请链接</div>
          <input class="txt-input" v-model="link" />
          <div class="btn" @click="copyUrl">复制</div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗 -->
    <el-dialog
      width="720px"
      custom-class="pt-dialog"
      :visible.sync="dialogFormVisible3"
    >
      <div class="dialog-main">
        <div class="log-head log-confirm">
          <span class="log-text">是否参与</span>
          <img :src="group.openGroupUser.portrait" class="log-portrait" />
          <span class="log-text">的拼团队伍</span>
          <div class="pt-time pt-count">
            <mv-count-down
              :startTime="startTime"
              :endTime="group.terminationTime"
              :endText="group.status.name"
              :dayTxt="'天'"
              :hourTxt="':'"
              :minutesTxt="':'"
              :secondsTxt="''"
              :isStart="isStart"
            ></mv-count-down>
          </div>
          <span class="log-text">后结束</span>
        </div>
        <div class="log-row flex flex-ac">
          <div class="flex_bd course">
            <div class="ke-card course-name flex flex-ac">
              <div class="flex_bd">
                <div class="k-title">【{{ group.buyComboName }}】</div>
              </div>
              <div class="k-photo">
                <img :src="group.cover" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="log-foot pt-foot">
          <div class="log-tip">拼团不成功,全额自动退款</div>
        </div>
        <div class="log-foot pt-foot">
          <div class="btn-confirm" @click="checkMember(group)">确定</div>
          <div class="btn-close" @click="dialogFormVisible3 = false">取消</div>
        </div>
      </div>
    </el-dialog>

    <!-- 弹窗 -->
    <el-dialog
      width="466px"
      custom-class="pt-dialog"
      :visible.sync="dialogFormVisible4"
    >
      <div class="dialog-success" style="width: 100%">
        <div class="log-head">
          <span class="log-txt">是否确定退出拼团？</span>
        </div>
        <div class="log-foot pt-foot">
          <div class="btn-confirm" @click="backGroup">确定</div>
          <div class="btn-close" @click="dialogFormVisible4 = false">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MvCountDown from "@/components/MvCountDown/MvCountDown";
import {
  getActiveDetail,
  selfGroupBuyList,
  getWebSocketURL,
  backGroup,
  checkMember,
  getGroup,
  shareCount,
} from "../../service/index.js";
import { setStorage, setStorageForArray } from "../../utils/common";
export default {
  components: { MvCountDown },
  data() {
    return {
      radioV: 0,
      visible: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      // 倒计时
      startTime: new Date().getTime(), // 开始时间（时间戳）
      endText: "已结束",
      swiperOption: {
        loop: false,
        // init:false,
        swiperKey: Math.random().toString(36).substr(2),
        direction: "vertical",
        slidesPerView: 5,
        observer: true,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      activeDetails: [],
      activeId: this.$route.query.activeId,
      groupId: "",
      selfGroups: [],
      shortSelfGroups: [],
      isShort: true,
      socketPath: "",
      socket: "",
      lockReconnect: false, //是否真正建立连接
      timeout: 3000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      allGroup: [],
      link: "",
      isStart: false,
      group: {
        openGroupUser: {},
        status: {},
      },
      backGroupId: 0,
    };
  },
  created() {
    // this.setEndTime(11110);
    // console.log(this.startTime);'
    this.getSelfGroupBuyList();
  },
  mounted() {
    window.scrollTo(0, 0);
    getWebSocketURL().then((res) => {
      this.socketPath = res.result;
      this.socketInit();
    });
    this.groupId = this.$route.query.groupId;
    if (this.groupId) {
      this.joinGroup();
    }
    if (this.activeId) {
      this.shareCount();
    }
  },
  methods: {
    backGroupOpen(groupId) {
      this.dialogFormVisible4 = true;
      this.backGroupId = groupId;
    },
    joinGroup() {
      this.dialogFormVisible3 = true;

      getGroup(this.groupId).then((res) => {
        if (res.success) {
          this.group = res.result;
          this.isStart = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    checkMember(item, groupId) {
      checkMember(item.comboId, this.activeId, groupId ? groupId : "").then(
        (res) => {
          if (res.success) {
            this.buyCombo(item, groupId);
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    backGroup() {
      backGroup(this.backGroupId).then((res) => {
        if (res.success) {
          this.$message.success("退团成功");
          this.dialogFormVisible4 = false;
          this.getSelfGroupBuyList();
        }
      });
    },
    getLink(groupId) {
      this.link =
        (process.env.NODE_ENV === "production"
          ? "https://www.cspiration.com/#/groupBuyDeatil"
          : "http://test.cspiration.com/#/") +
        "groupBuyDetail?activeId=" +
        this.activeId +
        "&groupId=" +
        groupId +
        "&isShare=true";
      this.dialogFormVisible2 = true;
    },
    copyUrl() {
      var domUrl = document.createElement("input");
      domUrl.value = this.link;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message.success("复制成功");
    },
    socketInit() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        // console.log(this.socketPath)
        this.socket = new WebSocket(this.socketPath);
        // 监听socket连接
        this.socket.onopen = this.socketOpen;
        // 监听socket错误信息
        // this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    getMessage(msg) {
      this.reset();
      if (msg.data && JSON.parse(msg.data).resultMessage) {
        if (JSON.parse(msg.data).resultMessage.length != this.allGroup.length) {
          this.allGroup = JSON.parse(msg.data).resultMessage;
          if (this.allGroup.length > 5) {
            this.swiperKey = Math.random().toString(36).substr(2);
          }

          //  var _this = this;
          // setTimeout(() =>{
          //   _this.$refs.mySwiper.$swiper.init();
          // },1500)
        }
      }
    },
    socketOpen() {
      this.start();
      this.socket.send(
        JSON.stringify({
          method: 2,
          message: {
            activeId: this.activeId,
          },
        })
      );
    },
    reconnect() {
      //重新连接
      console.log(1111);
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.socketInit();
        that.lockReconnect = false;
      }, 1000);
    },
    reset() {
      //重置心跳
      //清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      //重启心跳
      this.start();
    },
    start() {
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.socket.readyState == 1) {
          //如果连接正常
          self.socket.send(
            JSON.stringify({
              method: 2,
              message: {
                activeId: self.activeId,
              },
            })
          );
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.socket.close();
        }, self.timeout);
      }, self.timeout);
    },
    getSelfGroupBuyList() {
      this.shortSelfGroups = [];
      selfGroupBuyList({ activeId: this.activeId }).then((res) => {
        if (res.success) {
          this.selfGroups = res.result;
          res.result.forEach((item, i) => {
            if (i < 3) {
              this.shortSelfGroups.push(item);
            }
          });
        }
      });
    },
    buyCombo(item, groupBuyId) {
      console.log(item);
      let cart = {
        imgUrl: item.cover,
        objectName: item.buyComboName,
        objectId: item.comboId,
        classId: item.courseClassId,
        price: item.currentPrice,
        realPrice: item.originalPrice,
        comboId: item.comboId,
        activeId: item.id,
        groupBuyId: groupBuyId,
      };

      setStorage("totalprice", item.currentPrice || 0);
      setStorage("realPrice", item.originalPrice || 0);
      setStorageForArray("orderSelect", [cart]);

      this.$router.push("/confirm?type=1");
    },
    getCombos() {
      this.dialogFormVisible = true;
      getActiveDetail(this.activeId).then((res) => {
        if (res.success) {
          this.activeDetails = res.result;
        }
      });
    },
    shareCount() {
      if (this.$route.query.isShare) {
        shareCount({
          activeId: this.$route.query.activeId,
          type: 0,
        });
      } else {
        shareCount({
          activeId: this.$route.query.activeId,
          type: 1,
        });
      }
    },
  },
};
</script>

<style scoped="scoped">
@import url("../../assets/font/YouSheBiaoTiHei.css");
@import url("../../assets/font/SourceHanSansRegular.css");

.pt-foot {
  display: flex;
  justify-content: center;
}

.pt-foot .btn-confirm {
  background: linear-gradient(141deg, #ff4e50 0%, #f9d423 100%);
  color: #fff;
  /* text-shadow:0px 1px 2px rgb(184 0 0 / 50%); */
  width: 88px;
  height: 32px;
  border-radius: 4px;
  line-height: 32px;
  margin: 0 20px;
}
.pt-foot .btn-close {
  background: linear-gradient(141deg, #62686e 0%, #b2bac2 100%);
  color: #fff;
  /* text-shadow:0px 1px 2px rgb(184 0 0 / 50%); */
  width: 88px;
  height: 32px;
  border-radius: 4px;
  line-height: 32px;
  margin: 0 20px;
}
.d-flex-code input {
  font: 400 16px Arial;
  border-color: #f4f4f4;
  background-color: #ebebeb;
  border-style: groove;
  margin-right: 10px;
}
.gift-fixed {
  position: fixed;
  top: 50%;
  right: 10px;
  z-index: 1999;
  width: 166px;
  height: 181px;
  cursor: pointer;
  background: url(../../assets/images/pintuan_images/liwu.png) no-repeat;
}

.gift-fixed .box-popover {
  background: url(../../assets/images/pintuan_images/popover.png) no-repeat;
  width: 120px;
  height: 180px;
  position: absolute;
  bottom: 160px;
  left: 50%;
  margin-left: -60px;
}

.box-popover {
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
}

.box-popover .inner {
  padding: 15px 0;
}

.box-popover .txt1 {
  color: #fa6400;
  margin-top: 5px;
}

.box-popover .txt2 {
  color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 1600px) {
  .gift-fixed {
    left: 50%;
    margin-left: 590px;
  }
}

.pt-false {
  color: rgba(0, 0, 0, 0.5);
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
}

.pt-success {
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  color: #de1306;
}

.pt-rular {
  border-radius: 10px;
  border: 8px solid #fa726b;
  background-color: #fffffe;
  padding: 45px 38px;
  margin-top: 20px;
}

.pt-div-more {
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.pt-div-more span {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 27px;
  text-align: center;
  display: inline-block;
  border-bottom: 1px solid #fff;
}

.rular-body {
  color: #f95959;
  font-size: 12px;
}

.pt-rular-hd {
  text-align: center;
  margin-bottom: 15px;
}

.pt-banner {
  position: relative;
}
.pt-banner img {
  vertical-align: top;
}
.pt-wrapper {
  padding-top: 30px;
  padding-bottom: 150px;
  background-image: linear-gradient(#ffefdb, #ffb381);
}

.bk-cell {
  display: block;
  font-size: 0;
}

.pt-progress {
  position: absolute;
  left: 0;
  bottom: -24px;
  text-align: center;
  width: 100%;
}
.pt-progress img {
  width: 900px;
}

.pt-clk {
  position: absolute;
  bottom: 30%;
  right: 26%;
  width: 240px;
  cursor: pointer;
}

.pt-panel {
  position: relative;
  background-image: linear-gradient(#f84b48, #fffbe4);
  border-radius: 14px;
}

.pt-panel-body {
  padding: 36px 120px;
}

.pt-panel-hd {
  margin-bottom: 18px;
  font-size: 0;
  text-align: center;
}

.pt-list-card {
  position: relative;
  background: #fff7f7;
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 38px 48px;
}

.pt-list-card::after {
  position: absolute;
  display: block;
  content: "";
  height: 6px;
  left: 2px;
  right: 2px;
  bottom: -4px;
  background: #fe591d;
  border-radius: 0px 0px 8px 8px;
}

.list-pic {
  margin-right: 20px;
}

.list-pic img {
  width: 184px;
  height: 104px;
}

.pt-list-title {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #fa6400;
  line-height: 27px;
}

.pt-time {
  height: 28px;
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ff352e;
  line-height: 42px;
  padding-left: 32px;
}
.pt-count {
  display: inline-block;
  font-size: 20px;
  padding: 0;
  line-height: 26px;
}

.list-button {
  width: 134px;
  text-align: center;
  margin-left: 50px;
}

.list-button .rt-tip {
  color: #ff352e;
  margin-top: 5px;
}

.list-button .el-button {
  padding: 0;
  border: 0;
  width: 133px;
  height: 42px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  display: block;
  margin-left: 0;
}

.list-button .btn-together {
  background: url(../../assets/images/pintuan_images/button1.png) no-repeat;
}

.list-button .btn-out {
  background: url(../../assets/images/pintuan_images/button2.png) no-repeat;
}

.pt-group .plus-icon {
  width: 20px;
  height: 20px;
  margin: 0 20px;
  background: url(../../assets/images/pintuan_images/plus.png) no-repeat;
}

.group-item {
  text-align: center;
}
.group-item img {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  margin: 0 auto;
  display: flex;
}
.group-item .el-button {
  width: 60px;
  height: 24px;
  font-size: 12px;
  padding: 0;
  border-radius: 23px;
  border: 0;
  margin-top: 15px;
}

.group-item .btn-star {
  background: linear-gradient(327deg, #f18888 0%, #f95959 100%);
  color: #fff;
}

.group-item .btn-mem {
  background: linear-gradient(142deg, #f6c975 0%, #fff5db 100%, #fff5db 100%);
  color: #ff8c00;
}

.item-avatar {
  text-align: center;
  margin: 0 auto;
  height: 44px;
  width: 44px;
  border-radius: 44px;
  background-color: #fa6400;
  color: #fff;
  font-size: 24px;
  line-height: 44px;
}

.item-add {
  margin: 0 auto;
  height: 44px;
  width: 44px;
  background: url(../../assets/images/pintuan_images/add.png) no-repeat;
}

.flex {
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
  display: -moz-box; /* 老版本语法: Firefox (buggy) */
  display: -ms-flexbox; /* 混合版本语法: IE 10 */
  display: -webkit-flex; /* 新版本语法: Chrome 21+ */
  display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
}

/* 主轴居中 */
.flex-hc {
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/* 侧轴居中 */
.flex-ac {
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

/*充满父元素*/
.flex_bd {
  -prefix-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

/* 弹窗 */
.log-row {
  margin-bottom: 20px;
}

.log-head {
  margin-bottom: 20px;
  text-align: center;
}

.log-head .log-tit {
  display: inline-block;
  background-color: #ff5247;
  color: #fff;
  padding: 0 20px;
  line-height: 26px;
  font-size: 14px;
}

.log-head .log-text {
  display: inline-block;
  /* background-color: #ff5247; */
  color: #000;
  padding: 0 20px;
  line-height: 26px;
  font-size: 14px;
}

.log-head .log-txt {
  display: inline-block;
  /* background-color: #ff5247; */
  color: #000;
  padding: 0 20px;
  line-height: 26px;
  font-size: 16px;
}
.log-head .log-portrait {
  width: 32px;
  border: 1px solid #fa6400;
  border-radius: 40px;
}
.log-confirm {
  display: inline-flex;
  line-height: 32px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}
.log-label {
  margin-right: 15px;
  color: #000000;
}

.ke-card {
  padding: 20px 36px;
  border-radius: 10px;
  background-color: #fafafa;
  max-width: 480px;
}
.course {
  text-align: center;
}
.course-name {
  margin: 0 auto;
  display: inline-block;
  width: 100%;
}
.course-name .flex_bd {
  text-align: center;
}

.course-name .k-photo {
  text-align: center;
}
.k-photo {
  margin-right: 15px;
  font-size: 0;
}

.k-photo img {
  width: 148px;
  height: 84px;
}

.k-title {
  color: #fa6807;
  font-size: 16px;
  margin-bottom: 10px;
}

.k-info {
  font-size: 16px;
  color: #fa6807;
}

.log-foot {
  text-align: center;
}

.log-foot .log-tip {
  color: #ff645a;
  margin-bottom: 25px;
  font-size: 12px;
}

.log-foot .btn-pl {
  padding: 0;
  border: 0;
  width: 180px;
  height: 32px;
  background: linear-gradient(141deg, #ff4e50 0%, #f9d423 100%);
  border-radius: 20px;
  color: #ffffff;
  border: 0;
  text-shadow: 0px 1px 2px rgba(184, 0, 0, 0.5);
}

.dialog-success {
  margin: 0 auto;
  width: 374px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.dialog-success .log-head {
  margin-bottom: 40px;
}

.dialog-success .log-head .log-tit {
  display: block;
}

.d-flex-code {
  margin-bottom: 30px;
}

.d-flex-code .txt {
  color: #ff5247;
  font-size: 14px;
}

.d-flex-code .btn {
  width: 78px;
  height: 32px;
  border-radius: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #ff5247;
  font-size: 14px;
}

.d-flex-code .txt-input {
  background-color: #f4f4f4;
  text-align: center;
  width: 190px;
  height: 32px;
  line-height: 32px;
  color: #666666;
  font-size: 16px;
  margin: 0 10px;
}

.d-flex-code .text {
  color: #ff5247;
  font-size: 14px;
}
</style>
<style>
.pt-dialog {
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/pintuan_images/pp-bg.png);
  background-size: cover;
}

.pt-dialog .el-dialog__body {
  padding: 10px 70px 30px;
}

.pt-dialog .el-radio {
  border-radius: 40px;
  margin-right: 5px;
}

.pt-dialog .el-radio .el-radio__input {
  position: absolute;
  opacity: 0;
}

.pt-dialog .el-radio__input.is-checked + .el-radio__label {
  color: #fff;
}

.pt-dialog .el-radio.is-bordered.is-checked {
  border: 0;
  background: linear-gradient(141deg, #ff4e50 0%, #f9d423 100%);
}

.pt-dialog .el-dialog__headerbtn .el-dialog__close {
  font-size: 32px;
  color: #666666;
}

.pt-list .swiper-container {
  height: 1020px;
}

.pt-list .swiper-slide {
  height: 184px;
}
</style>