import { render, staticRenderFns } from "./pintuan-2.vue?vue&type=template&id=440c43de&scoped=true"
import script from "./pintuan-2.vue?vue&type=script&lang=js"
export * from "./pintuan-2.vue?vue&type=script&lang=js"
import style0 from "./pintuan-2.vue?vue&type=style&index=0&id=440c43de&prod&scoped=scoped&lang=css"
import style1 from "./pintuan-2.vue?vue&type=style&index=1&id=440c43de&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440c43de",
  null
  
)

export default component.exports