export function getStorage(key, isJson = false) {
    const item = window.localStorage.getItem(key);
    if (item) {
        if (isJson) {
            return JSON.parse(decodeURIComponent(item))
        } else {
            return decodeURIComponent(item);
        }
    }
    return "";
}

export function setStorage(key, item, isJson = false) {
    if (isJson) {
        window.localStorage.setItem(key, encodeURIComponent(JSON.stringify(item)));
    } else {
        window.localStorage.setItem(key, encodeURIComponent(item));
    }
}

export function setStorageForArray(key, array) {
    if (array && array.length > 0) {
        let temp = [];
        array.forEach(item => temp.push(JSON.stringify(item)));
        let arrStr = encodeURIComponent(temp.join("*"));
        window.localStorage.setItem(key, arrStr);
    }
}

export function getStorageForArray(key, array) {
    let storage = window.localStorage.getItem(key);
    if (storage) {
        storage = decodeURIComponent(storage);
        storage = storage.split("*");
    }
    return storage || [];
}

export function removeStorage(key) {
    window.localStorage.removeItem(key);
}

export function navigateBack(lv = -1, delay = 0) {
    const timeout = setTimeout(() => {
        window.history.go(lv);
        clearTimeout(timeout);
    }, delay)

}

